import dayjs from "dayjs";
import { ERROR_TYPES, publicKeyToBase58Check } from "deso-protocol";
import { uniqBy } from "lodash";
import snakeCase from "lodash/snakeCase";
import { DateRange } from "react-day-picker";
import { client } from "../graphql/client";
import {
  CoreAccountFieldsFragment,
  GetTransactionByHashDocument,
  ValidatorFragment,
} from "../graphql/codegen/graphql";
import {
  LeaderboardUser,
  TRANSACTION_TYPES_BY_INDEX,
  TRANSACTION_TYPES_EXTENDED,
} from "../types";
import {
  DESO_NETWORK,
  DIAMOND_APP_URL,
  EPOCHS_UNTIL_UNLOCKABLE,
  NODE_URI,
  TXN_FINALITY_POLL_RETRIES,
} from "./constants";
import { desoNanosToUSD, formatUSD } from "./currency";
import axios from "axios";
import { toast } from "@/components/ui/use-toast";

export const shortenLongWord = (
  key?: string | null,
  endFirstPartAfter = 4,
  startSecondPartAfter = 4,
  separator = "...",
) => {
  if (!key || key.length <= endFirstPartAfter + startSecondPartAfter) {
    return key || "";
  }

  return [
    key.slice(0, endFirstPartAfter),
    separator,
    key.slice(-startSecondPartAfter),
  ].join("");
};

export function bytesToBase64(bytes: Uint8Array): string {
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join("");
  return btoa(binString);
}

export function range(num: number) {
  return Array.from(Array(num).keys());
}

export function bytesToHex(byteArray: Uint8Array) {
  return Array.from(byteArray, function (byte) {
    return ("0" + (byte & 0xff).toString(16)).slice(-2);
  }).join("");
}

export function abbreviateNumber(value: number): string {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 2,
  }).format(value);
}

export function formatTxnType(txnType?: number | null) {
  return txnType !== null && txnType !== undefined
    ? snakeCase(
        TRANSACTION_TYPES_BY_INDEX[txnType].replace("TxnType", ""),
      ).toUpperCase()
    : "Unknown";
}

export function formatTxnFees(feeNanos: number, exchangeRate: number) {
  const feeUsdNanos = desoNanosToUSD(feeNanos, exchangeRate);
  return formatUSD(feeUsdNanos, true, 7);
}

export function getTransactionInfo(index: number) {
  const typeByIndex = TRANSACTION_TYPES_BY_INDEX[index];
  return TRANSACTION_TYPES_EXTENDED.find((e) => e.type === typeByIndex);
}

export const publicKeyByteArrayToBase58Check = (byteArray: Array<number>) => {
  return publicKeyToBase58Check(Uint8Array.from(byteArray), {
    network: DESO_NETWORK,
  });
};

export const formatTxnUsername = (key: string) => {
  if (!key) {
    return "";
  }
  const encoded = Uint8Array.from(atob(key), (c) => c.charCodeAt(0));
  return publicKeyToBase58Check(encoded, {
    network: DESO_NETWORK,
  });
};

export const isTestnet = () => {
  return DESO_NETWORK === "testnet";
};

export const linkToDisplayApp = () => {
  return isTestnet() ? NODE_URI : DIAMOND_APP_URL;
};

export const isDesoPublicKey = (value: string) => {
  // User public key: Starts with 'BC', length 55, base58 characters
  return (
    /^BC[1-9A-HJ-NP-Za-km-z]{53}$/.test(value) ||
    /^tBC[1-9A-HJ-NP-Za-km-z]{51}$/.test(value)
  );
};

export const isBlockHash = (value: string) => {
  // Block hash: Hex string of length 64
  return /^[0-9A-Fa-f]{64}$/.test(value);
};

export const copyTextToClipboard = async (text: string) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export const formatDisplayName = (
  username?: string | null,
  publicKey?: string | null,
  addAt = false,
  shorten = true,
) => {
  let result = "";

  if (username) {
    result = `${addAt ? "@" : ""}${username}`;
  } else if (publicKey) {
    result = shorten ? shortenLongWord(publicKey) : publicKey;
  }

  return result;
};

export const parseLeaderboardUser = <T>(
  rawValue: T,
  publicKey?: string | null,
  username?: string | null,
  value?: string | null,
): LeaderboardUser<T> => {
  return {
    publicKey: publicKey || "",
    username: username || "",
    value: value || "",
    rawValue,
  };
};

export const formatDateFiltersPayload = (date: DateRange) => {
  return {
    greaterThanOrEqualTo: dayjs(date.from).startOf("day").toISOString(),
    lessThan: dayjs(date.to).endOf("day").toISOString(),
  };
};

export const concatUserResponses = (
  exactMatch?: CoreAccountFieldsFragment | null,
  usersList?: Array<CoreAccountFieldsFragment | null>,
) => {
  const nonEmptyUsers = (usersList || []).filter((e) => e !== null);

  return uniqBy(
    [exactMatch, ...nonEmptyUsers].filter((e) => !!e && e.publicKey),
    "publicKey",
  ) as Array<CoreAccountFieldsFragment>;
};

export const parseTimestamp = (rawTimestamp: string | undefined) => {
  if (!rawTimestamp) {
    return null;
  }
  return dayjs(
    !/\.\d*$/.test(rawTimestamp) ? `${rawTimestamp}.000Z` : `${rawTimestamp}Z`,
  ).valueOf();
};

export const getValidatorStakePercentage = (
  validator: ValidatorFragment,
  totalStakedAcrossAllValidators: bigint,
) => {
  return (
    Number(validator?.totalStakeAmountNanos) /
    Number(totalStakedAcrossAllValidators)
  );
};

export const getViewerStakeAmountNanos = (validator: ValidatorFragment) => {
  return validator?.viewerStake?.nodes?.[0]?.stakeAmountNanos ?? 0;
};

export const getUnlockableEpochBoundary = (currentEpoch: number) => {
  return BigInt(currentEpoch) - BigInt(EPOCHS_UNTIL_UNLOCKABLE);
};

export const pollForTxnFinalized = async (txHash: string) => {
  let callbackCount = 0;

  return new Promise((resolve, reject) => {
    const callback = async () => {
      if (callbackCount > TXN_FINALITY_POLL_RETRIES) {
        reject(
          new Error(
            `Timed out waiting for finalized transaction. Transaction hash: ${txHash}`,
          ),
        );
        return;
      }

      callbackCount++;

      try {
        const result = await client.query({
          query: GetTransactionByHashDocument,
          variables: {
            transactionHash: txHash,
          },
          fetchPolicy: "no-cache",
        });
        const transactionId =
          result.data?.transactionByTransactionHash?.transactionId;
        if (transactionId) {
          resolve(transactionId);
        } else {
          // Poll every 1 second until we get a transaction id or we surpass the
          // polling threshold.
          setTimeout(callback, 1000);
        }
      } catch (error) {
        reject(error);
      }
    };

    callback();
  });
};

export type CheckNodeStatusResponse = {
  error?: string;
  success?: boolean;
  pending?: boolean;
};

export const checkNodeStatus = async (NodeHostPort: string) => {
  try {
    await axios.post(`${NODE_URI}/api/v0/check-node-status`, {
      NodeHostPort,
    });
    return { success: true };
  } catch (error: any) {
    return { error: error.response.data.error };
  }
};

export const handleIdentityClosedWithoutInteraction = (
  error: any,
  message: string,
) => {
  if (error.type !== ERROR_TYPES.IDENTITY_WINDOW_CLOSED) {
    toast({
      variant: "destructive",
      title: "Error",
      description: message,
    });
  }
};
