import TransactionMetrics from "@/components/shared/transaction-metrics";
import { BoxIcon } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { useEffect, useState } from "react";
import TableTransactions from "@/components/shared/table-transactions";
import {
  AffectedPublicKeyFilter,
  AffectedPublicKeysDocument,
  AffectedPublicKeysOrderBy,
  DashboardStatsDocument,
  Transaction,
  TransactionFilter,
  TransactionsDocument,
  TransactionsOrderBy,
  TransactionsQuery,
} from "../graphql/codegen/graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { client } from "../graphql/client";
import { useToast } from "@/components/ui/use-toast";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import TransactionAdvancedFilters from "@/components/transactions/transaction-advanced-filters";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import { useTitle } from "../hooks/use-title";
import { TransactionFilters } from "../types";
import Spinner from "@/components/shared/spinner";
import { LOCAL_STORAGE_KEYS } from "../utils/constants";
import { formatDateFiltersPayload } from "../utils/helpers";

const ITEMS_PER_PAGE = 10;
const PER_PAGE_OPTIONS = [ITEMS_PER_PAGE, 20, 50, 100];
const DEFAULT_FILTERS: TransactionFilters = {
  date: {
    from: undefined,
    to: undefined,
  },
  txnHash: "",
  blockHeight: "",
  txnTypes: [],
  transactorPublicKeys: [],
  affectedUsers: [],
};

const getArrayFromQuery = (params: URLSearchParams, param: string) => {
  return params.get(param)?.split(",") || [];
};

export const Transactions = () => {
  useTitle("Transactions");

  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const initTransactionTypeIndexes = getArrayFromQuery(
    searchParams,
    "txnTypes",
  ).map((e) => Number.parseInt(e));
  const initTransactorPublicKeys = getArrayFromQuery(
    searchParams,
    "transactors",
  );
  const initAffectedPublicKeys = getArrayFromQuery(
    searchParams,
    "affectedUsers",
  );

  const itemsPerPageStored = localStorage.getItem(
    LOCAL_STORAGE_KEYS.transactionsPerPage,
  );
  const initItemsPerPage = itemsPerPageStored
    ? Number.parseInt(itemsPerPageStored)
    : ITEMS_PER_PAGE;

  const [filters, setFilters] = useState<TransactionFilters>({
    txnHash: searchParams.get("txnId") || DEFAULT_FILTERS.txnHash,
    blockHeight: searchParams.get("blockHeight") || DEFAULT_FILTERS.blockHeight,
    date: {
      from:
        searchParams.get("from") && dayjs(searchParams.get("from")).isValid()
          ? new Date(searchParams.get("from") as string)
          : DEFAULT_FILTERS.date.from,
      to:
        searchParams.get("to") && dayjs(searchParams.get("to")).isValid()
          ? new Date(searchParams.get("to") as string)
          : DEFAULT_FILTERS.date.from,
    },
    txnTypes: initTransactionTypeIndexes,
    transactorPublicKeys: initTransactorPublicKeys,
    affectedUsers: initAffectedPublicKeys,
  });
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(initItemsPerPage);
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [applyFiltersCounter, setApplyFiltersCounter] = useState<number>(0);
  const [loadingPerPage, setLoadingPerPage] = useState<boolean>(false);

  const [fetchTransactionsLazy, { loading: loadingTransactions }] =
    useLazyQuery(TransactionsDocument, {
      client,
    });
  const [
    fetchAffectedUserTransactionsLazy,
    { loading: loadingAffectedUserTransactions },
  ] = useLazyQuery(AffectedPublicKeysDocument, {
    client,
  });
  const { loading: loadingStats, data: dashboardStats } = useQuery(
    DashboardStatsDocument,
    {
      client,
    },
  );
  const transactionMetrics = dashboardStats?.dashboardStats?.nodes[0] || null;

  const fetchTransactions = async (filters: TransactionFilters) => {
    let data: TransactionsQuery | undefined;

    if (filters.affectedUsers?.length) {
      let filtersPayload: AffectedPublicKeyFilter = {
        transaction: {
          blockHeight: {
            isNull: false,
          },
        },
      };

      if (filters.txnHash) {
        filtersPayload = {
          ...filtersPayload,
          transaction: {
            ...filtersPayload.transaction,
            transactionHash: {
              equalTo: filters.txnHash,
            },
          },
        };
      }
      if (filters.blockHeight) {
        filtersPayload = {
          ...filtersPayload,
          transaction: {
            ...filtersPayload.transaction,
            blockHeight: {
              equalTo: filters.blockHeight,
            },
          },
        };
      }
      if (filters.date.from && filters.date.to) {
        filtersPayload = {
          ...filtersPayload,
          timestamp: formatDateFiltersPayload(filters.date),
        };
      }
      if (filters.txnTypes.length) {
        filtersPayload = {
          ...filtersPayload,
          transaction: {
            ...filtersPayload.transaction,
            txnType: {
              in: filters.txnTypes,
            },
          },
        };
      }
      if (filters.transactorPublicKeys.length) {
        filtersPayload = {
          ...filtersPayload,
          transaction: {
            ...filtersPayload.transaction,
            publicKey: {
              in: filters.transactorPublicKeys,
            },
          },
        };
      }
      if (filters.affectedUsers.length) {
        filtersPayload = {
          ...filtersPayload,
          publicKey: {
            in: filters.affectedUsers,
          },
          isDuplicate: {
            equalTo: false,
          },
        };
      }

      data = await fetchAffectedUserTransactionsLazy({
        variables: {
          first: itemsPerPage,
          orderBy: [AffectedPublicKeysOrderBy.TimestampDesc],
          offset,
          filter: filtersPayload,
          withTotal: false,
        },
      }).then((res) => {
        return {
          transactions: {
            pageInfo: {
              hasNextPage: res.data?.affectedPublicKeys?.pageInfo.hasNextPage,
              hasPreviousPage:
                res.data?.affectedPublicKeys?.pageInfo.hasPreviousPage,
            },
            nodes: res.data?.affectedPublicKeys?.nodes.map(
              (e) => e?.transaction,
            ),
          },
        } as TransactionsQuery;
      });
    } else {
      let filtersPayload: TransactionFilter = {
        blockHeight: {
          isNull: false,
        },
      };

      if (filters.txnHash) {
        filtersPayload = {
          ...filtersPayload,
          transactionHash: {
            equalTo: filters.txnHash,
          },
        };
      }
      if (filters.blockHeight) {
        filtersPayload = {
          ...filtersPayload,
          blockHeight: {
            equalTo: filters.blockHeight,
          },
        };
      }
      if (filters.date.from && filters.date.to) {
        filtersPayload = {
          ...filtersPayload,
          timestamp: formatDateFiltersPayload(filters.date),
        };
      }
      if (filters.txnTypes.length) {
        filtersPayload = {
          ...filtersPayload,
          txnType: {
            in: filters.txnTypes,
          },
        };
      }
      if (filters.transactorPublicKeys.length) {
        filtersPayload = {
          ...filtersPayload,
          publicKey: {
            in: filters.transactorPublicKeys,
          },
        };
      }
      if (filters.affectedUsers.length) {
        filtersPayload = {
          ...filtersPayload,
          affectedPublicKeys: {
            some: {
              publicKey: {
                in: filters.affectedUsers,
              },
              isDuplicate: {
                equalTo: false,
              },
            },
          },
        };
      }

      data = await fetchTransactionsLazy({
        variables: {
          first: itemsPerPage,
          orderBy: [
            TransactionsOrderBy.TimestampDesc,
            TransactionsOrderBy.IndexInBlockDesc,
          ],
          offset,
          filter: filtersPayload,
          withTotal: false,
        },
      }).then((e) => e.data);
    }

    setHasPrevPage(data?.transactions?.pageInfo.hasPreviousPage || false);
    setHasNextPage(data?.transactions?.pageInfo.hasNextPage || false);

    return (
      (data?.transactions?.nodes?.filter(
        (e) => e !== null,
      ) as Array<Transaction>) || []
    );
  };

  const checkForConflictingFilters = () => {
    const conflictingFilters = [
      !!filters.txnHash,
      !!filters.blockHeight,
      filters.date.from && filters.date.to,
    ];
    const totalConflictingFiltersApplied = conflictingFilters.filter((e) =>
      Boolean(e),
    );

    if (totalConflictingFiltersApplied.length >= 2) {
      const filterTypes = [];

      if (filters.txnHash) {
        filterTypes.push("Transaction Hash");
      }

      if (filters.blockHeight) {
        filterTypes.push("Block Height");
      }

      if (filters.date.from && filters.date.to) {
        filterTypes.push("Dates");
      }

      const toastMessage = `We applied your filters, but you may see no results if your filters conflict with one another. Please simplify your selection to a single filter only between ${filterTypes.join(
        ", ",
      )}`;
      toast({
        variant: "warning",
        title: "Conflicting filters found",
        duration: 15000,
        description: toastMessage,
      });
    }
  };

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const response = await fetchTransactions(filters);

        if (response.length === 0) {
          checkForConflictingFilters();
        }

        setTransactions(response);
      } catch (e: any) {
        toast({
          variant: "destructive",
          title: "Error",
          description: `There was a problem getting transactions. ${JSON.stringify(
            e,
          )}`,
        });
      } finally {
        setInitLoading(false);
        setLoadingPerPage(false);
      }
    };

    getTransactions();
  }, [offset, itemsPerPage, applyFiltersCounter]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.transactionsPerPage,
      itemsPerPage.toString(),
    );
  }, [itemsPerPage]);

  return (
    <main className="container-wide">
      <div className="text-left m-auto">
        <h1 className="text-2xl mb-4 text-black dark:text-white font-semibold">
          Transactions
        </h1>
      </div>

      <div className="mt-4 m-auto">
        <TransactionMetrics
          loading={loadingStats}
          dashboardStats={transactionMetrics}
        />

        <div className="my-12">
          <div className="flex justify-between items-center mb-6">
            <h3 className="flex items-center">
              <BoxIcon className="mr-2" />
              All Transactions
            </h3>

            <div className="flex items-center">
              {loadingPerPage && <Spinner className="mr-2" size={24} />}
              <span className="mr-3 inline-block">Show</span>
              <Select
                onValueChange={(e) => {
                  setOffset(0);
                  setLoadingPerPage(true);
                  setItemsPerPage(Number.parseInt(e));
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder={itemsPerPage} />
                </SelectTrigger>
                <SelectContent>
                  {PER_PAGE_OPTIONS.map((o) => (
                    <SelectItem key={o} value={o.toString()}>
                      {o}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="flex items-end justify-end p-4 py-2 border mb-6 rounded-xl">
            <TransactionAdvancedFilters
              initTxnIndexes={initTransactionTypeIndexes}
              initTransactorPublicKeys={initTransactorPublicKeys}
              initAffectedPublicKeys={initAffectedPublicKeys}
              filters={filters}
              setFilters={(e) => {
                if (!e) {
                  return;
                }

                setOffset(0);
                setFilters(e);
              }}
              onApplyFilters={() => {
                setInitLoading(true);

                const possibleQueryFilters = pickBy(
                  {
                    blockHeight: filters.blockHeight,
                    txnId: filters.txnHash,
                    from: filters.date.from
                      ? dayjs(filters.date.from).format("YYYY-MM-DD")
                      : undefined,
                    to: filters.date.to
                      ? dayjs(filters.date.to).format("YYYY-MM-DD")
                      : undefined,
                    txnTypes: filters.txnTypes?.length
                      ? filters.txnTypes.join(",")
                      : undefined,
                    transactors: filters.transactorPublicKeys.length
                      ? filters.transactorPublicKeys.join(",")
                      : undefined,
                    affectedUsers: filters.affectedUsers.length
                      ? filters.affectedUsers.join(",")
                      : undefined,
                  },
                  identity,
                ) as { [param: string]: string };

                setSearchParams(possibleQueryFilters);

                setApplyFiltersCounter((prev) => prev + 1);
              }}
              onClearFilters={() => {
                setInitLoading(true);
                setFilters({ ...DEFAULT_FILTERS });
                setSearchParams({});
                setApplyFiltersCounter((prev) => prev + 1);
              }}
            />
          </div>

          {initLoading ? (
            <div className="overflow-hidden rounded-xl">
              <Skeleton className="h-[48px] w-full rounded-none mb-1" />
              <Skeleton className="h-[300px] w-full rounded-none" />
            </div>
          ) : (
            <TableTransactions
              transactions={transactions}
              total={null}
              loadingPage={
                loadingTransactions || loadingAffectedUserTransactions
              }
              offset={offset}
              perPage={itemsPerPage}
              onPrevPage={() => {
                setOffset((prev) => prev - itemsPerPage);
              }}
              onNextPage={() => {
                setOffset((prev) => prev + itemsPerPage);
              }}
              hasPrevPage={hasPrevPage}
              hasNextPage={hasNextPage}
            />
          )}
        </div>
      </div>
    </main>
  );
};
