import { AtomIcon, LucideWallet } from "lucide-react";
import { Metric } from "../ui/metric";
import {
  ProfileTransactionStatByPublicKeyDocument,
  GetValidatorsOverviewDocument,
  MyStakeSummariesDocument,
} from "../../graphql/codegen/graphql";
import { useQuery } from "@apollo/client";
import { client } from "../../graphql/client";
import dayjs from "dayjs";
import {
  desoNanosToDeso,
  desoNanosToUSD,
  formatDecimalValue,
  formatUSD,
} from "../../utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import { Skeleton } from "@/components/ui/skeleton";
import { AccountDetailsFragment } from "../../graphql/codegen/graphql";

interface NetworkActivityProps {
  publicKey: string;
  user?: AccountDetailsFragment | null;
}

const SKELETON_NUM_OF_CARDS = 4;

const UserNetworkActivity = ({ publicKey, user }: NetworkActivityProps) => {
  const { exchangeRate } = useContext(MarketDataContext);

  const { loading: loadingNetworkActivity, data: userNetworkActivity } =
    useQuery(ProfileTransactionStatByPublicKeyDocument, {
      client,
      variables: {
        publicKey,
      },
    });

  const { firstTransactionTimestamp, latestTransactionTimestamp } =
    userNetworkActivity?.profileTransactionStatByPublicKey || {};
  const firstTransactionDate = dayjs(firstTransactionTimestamp);
  const latestTransactionDate = dayjs(latestTransactionTimestamp);

  const skeletonItemsIterator = Array.from(Array(SKELETON_NUM_OF_CARDS).keys());

  const { loading: loadingStakingSummary, data: myStakeSummaries } = useQuery(
    MyStakeSummariesDocument,
    {
      client,
      variables: {
        filter: {
          staker: {
            publicKey: {
              equalTo: publicKey,
            },
          },
        },
      },
    },
  );

  return (
    <div className="mb-12">
      <div className="mb-12">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <LucideWallet className="mr-2" />
            Wallet
          </h3>
        </div>
        <div className="grid md:grid-cols-3 gap-4">
          <Metric
            value={formatUSD(
              desoNanosToUSD(
                BigInt(user?.desoBalance?.balanceNanos || 0) +
                  BigInt(
                    myStakeSummaries?.myStakeSummaries?.nodes[0]?.totalStake ||
                      0,
                  ),
                exchangeRate,
              ),
            )}
            caption={
              formatDecimalValue(
                desoNanosToDeso(
                  (
                    BigInt(user?.desoBalance?.balanceNanos || 0) +
                    BigInt(
                      myStakeSummaries?.myStakeSummaries?.nodes[0]
                        ?.totalStake || 0,
                    )
                  ).toString(),
                ),
              ) + " DESO"
            }
            label="Total DESO"
          />
          <Metric
            value={formatUSD(
              desoNanosToUSD(
                user?.desoBalance?.balanceNanos || 0,
                exchangeRate,
              ),
            )}
            label="Unstaked DESO (Available)"
            caption={
              formatDecimalValue(
                desoNanosToDeso(user?.desoBalance?.balanceNanos || 0),
              ) + " DESO"
            }
          />
          <Metric
            value={formatUSD(
              desoNanosToUSD(
                myStakeSummaries?.myStakeSummaries?.nodes[0]?.totalStake || 0,
                exchangeRate,
              ),
            )}
            caption={
              formatDecimalValue(
                desoNanosToDeso(
                  myStakeSummaries?.myStakeSummaries?.nodes[0]?.totalStake || 0,
                ),
              ) + " DESO"
            }
            label="Staked DESO"
          />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <h3 className="mb-4 flex items-center">
          <AtomIcon className="mr-2" />
          Network Activity
        </h3>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        {loadingNetworkActivity || loadingStakingSummary ? (
          skeletonItemsIterator.map((i) => (
            <Skeleton className="w-full h-[80px]" key={i} />
          ))
        ) : (
          <>
            <Metric
              value={
                firstTransactionTimestamp
                  ? firstTransactionDate.format("MMM DD, YYYY")
                  : "No data"
              }
              caption={
                firstTransactionTimestamp
                  ? firstTransactionDate.fromNow()
                  : undefined
              }
              label="Joined"
            />

            <Metric
              value={formatDecimalValue(
                userNetworkActivity?.profileTransactionStatByPublicKey?.count ||
                  0,
              )}
              label="Total Transactions"
              tooltip="Total number of all on-chain transactions"
            />

            <Metric
              value={
                userNetworkActivity?.profileTransactionStatByPublicKey
                  ? formatUSD(
                      desoNanosToUSD(
                        userNetworkActivity?.profileTransactionStatByPublicKey
                          ?.totalFees,
                        exchangeRate,
                      ),
                    )
                  : 0
              }
              caption={
                userNetworkActivity?.profileTransactionStatByPublicKey
                  ? `${formatDecimalValue(
                      desoNanosToDeso(
                        userNetworkActivity?.profileTransactionStatByPublicKey
                          ?.totalFees,
                      ),
                      6,
                    )} DESO`
                  : undefined
              }
              label="Total Txn Fees Spent"
            />

            <Metric
              value={
                latestTransactionTimestamp
                  ? latestTransactionDate.fromNow()
                  : "No activity"
              }
              label="Recent Activity"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UserNetworkActivity;
